<template>
    <div>
        <vue-html2pdf :show-layout="true" :float-layout="false" :enable-download="false" :preview-modal="true"
            margin="[10,10,10,10]" filename="Stock List" :paginate-elements-by-height="1000" :pdf-quality="2"
            pdf-format="A4" pdf-orientation="portrait" :pdf-content-width="isprint == 0 ? '1100px' : '800px'"
            :manual-pagination="true" @progress="onProgress($event)" @beforeDownload="beforeDownload($event)"
            @hasDownloaded="hasDownloaded($event)" ref="html2Pdf">
            <section slot="pdf-content">
                <section class="pdf-item">
                    <div v-if="viewno == 1">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-10">
                                    <h3 class="page-header">
                                        <i class="fa fa fa-database animated bounceInDown show-info"></i>
                                        Stock List
                                    </h3>
                                </div>
                                <div class="col-md-2 mt-10" v-if="isprint == 0">
                                    <button @click="refresh()" class="btn btn-primary"><i class="fa fa-refresh"></i>
                                        Refresh</button>
                                </div>
                                <!-- <div class="col-md-6 ">
                        <div class="row" style="padding-top:18px;justify-content:flex-end;">
                            <div class="col-md-2" style="margin-right:25px">
                                <button @click="back()" class="btn btn-primary"><i class="fa fa-backward"></i> Back</button>
                            </div>
                        </div>
                    </div> -->
                            </div>
                        </div>
                        <div class="" v-if="isprint == 0">
                            <div class="p-10">
                                <div class="col-md-12 row mb-10">
                                    <div class="col-md-6 row">
                                        <div class="col-md-4 text-center">
                                            <label>Company : </label>
                                        </div>
                                        <div class="col-md-8">
                                            <select class="form-control form-cascade-control input-small .dropdown-menu"
                                                v-model="brandid">
                                                <option value="0">All</option>
                                                <option v-for="item in comapnies" :key="item.id" :value="item.id">{{
                                                    item.value }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-6 row">
                                        <div class="col-md-4 text-center">
                                            <label>Product : </label>
                                        </div>
                                        <div class="col-md-8">
                                            <select class="form-control form-cascade-control input-small .dropdown-menu"
                                                v-model="category_id">
                                                <option value="0">All</option>
                                                <option v-for="item in categories" :key="item.id" :value="item.id">{{
                                                    item.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 row">
                                    <div class="col-md-6 row">
                                        <div class="col-md-4 text-center">
                                            <label>Model Name :</label>
                                        </div>
                                        <div class="col-md-8">
                                            <input type="text" class="form-control form-cascade-control input-small"
                                                v-model="searchtext" />
                                        </div>
                                    </div>

                                    <div class="col-md-6 row">
                                        <div class="col-md-4 text-center">
                                            <label>Price :</label>
                                        </div>
                                        <div class="col-md-8">
                                            <select class="form-control form-cascade-control input-small"
                                                v-model="pricelist">
                                                <option value="0">All</option>
                                                <option value="1">Retail</option>
                                                <option value="2">Whole Sale</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-6 row mt-20">
                                        <div class="col-md-4 text-center">
                                            <label>Availability :</label>
                                        </div>
                                        <div class="col-md-8">
                                            <select class="form-control form-cascade-control input-small"
                                                v-model="availability" @click="checkAvailability()">
                                                <option value="0">All</option>
                                                <option value="1">Active</option>
                                                <option value="2">Nill</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-6 row mt-20">
                                        <div class="col-md-4 text-center">
                                            <label>Serial no. :</label>
                                        </div>
                                        <div class="col-md-8">
                                            <input type="text" class="form-control form-cascade-control input-small"
                                                v-model="serialno" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 text-center">
                                    <button class="btn bg-success text-white username btnsearch"
                                        @click="search()">Search</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-11 row text-center mt-10 mb-20">
                                    <div :class="isprint == 0 ? 'col-md-4' : 'col-md-6'">
                                        <div class="small_blog">
                                            <label>Total stock : </label>
                                            <span class="total ml-5">
                                                {{ totalqty }}
                                            </span>
                                        </div>
                                    </div>
                                    <div :class="isprint == 0 ? 'col-md-4' : 'col-md-6'">
                                        <div class="small_blog">
                                            <label>Total purchase : </label>
                                            <span class="total ml-5">
                                                <i class='bx bx-rupee'></i>
                                                {{ totalpurchasevalue }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1" v-if="isprint == 0">
                                    <button class="btn pdf " @click="generateReport()"> <i class="bx bxs-file-pdf"
                                            style="font-size:40px"></i></button>
                                </div>
                            </div>
                        </div>
                        <div v-if="this.stocklist.length > 0">
                            <table class="table table-bordered table-font">
                                <thead>
                                    <th>S.No.</th>
                                    <!-- <th>id</th> -->
                                    <th>Category</th>
                                    <th>Product</th>
                                    <th>Company</th>
                                    <th>Model Name</th>
                                    <th v-if="isprint == 0">Fetch</th>
                                    <th>Stock</th>
                                    <th v-if="isprint == 0">Purchase Price</th>
                                    <th v-if="pricelist == 0 || pricelist == 1">Retail Price</th>
                                    <th v-if="pricelist == 0 || pricelist == 2">Wholesale Price</th>
                                    <th>Emi Charges</th>
                                    <th v-if="isprint == 0">Detail</th>
                                </thead>
                                <tbody v-for="(item, index) in stocklist" :key="item.id">
                                    <tr>
                                        <td>{{ index + 1 }}</td>
                                        <!-- <td>{{ item.id }}</td> -->
                                        <td>{{ item.product?.category?.parent?.name }}</td>
                                        <td>{{ item.product?.category?.name }}</td>
                                        <td>
                                            <div v-for="company in item.product.attribute" :key="company.id">
                                                {{ getcompany(company.attribute_value_ids) }}
                                            </div>
                                        </td>
                                        <td>{{ item.product?.name }}</td>
                                        <td v-if="isprint == 0">
                                            <div @click="fetchBarcode(item)">
                                                <button class="btn btn-primary"
                                                    style="width: auto;font-size: 17px;"><span><i
                                                            class='bx bx-barcode-reader'></i></span></button>
                                            </div>
                                        </td>
                                        <td>
                                            <span
                                                :class="parseFloat(item.finalquantity) > 0 ? 'badge bg-seagreen' : 'badge bg-danger'">
                                                {{ parseFloat(item.finalquantity).toFixed(2) }}
                                            </span>
                                        </td>
                                        <td v-if="isprint == 0">{{ item.product?.cp }}</td>
                                        <td v-if="pricelist == 0 || pricelist == 1">
                                            <div>{{ parseFloat(item.product?.cp * (1 + (item.product?.retail /
                                                100))).toFixed(2) }}
                                                <br> <span v-if="isprint == 0"> {{ item.product?.retail ?? 0 }} %</span>
                                            </div>
                                        </td>
                                        <td v-if="pricelist == 0 || pricelist == 2">
                                            <div>{{ parseFloat(item.product?.cp * (1 + (item.product?.wholesale /
                                                100))).toFixed(2) }}
                                                <br> <span v-if="isprint == 0"> {{ item.product?.wholesale ?? 0 }}
                                                    %</span>
                                            </div>
                                        </td>
                                        <td>{{ JSON.parse(item?.product?.emi_cost)?.emi_cost ?? 0 }}</td>
                                        <td v-if="isprint == 0"><button class="btn bg-success text-white 
                                username btnsearch" @click="detail(item)">Detail</button></td>
                                    </tr>
                                    <tr v-if="check(item) && hideRow">
                                        <td colspan="12">
                                            <div class="col-md-12  flex-center-row">
                                                <div class="col-md-6">
                                                    <table class="table table-bordered table-font"
                                                        style="margin-bottom:0;color:brown;font-family:auto!important;font-size:14px!important;">
                                                        <thead>
                                                            <th style="width: 20px;">#</th>
                                                            <!-- <th>Id</th> -->
                                                            <th style="width: 50px;">Serial no./IMEI</th>
                                                            <th style="width: 50px;">Color</th>

                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(data, index) in barcodes" :key="data.id">
                                                                <td scope="row">{{ index + 1 }}</td>
                                                                <!-- <td>{{ data?.id }}</td> -->
                                                                <td>{{ data?.barcode }}</td>
                                                                <td>{{ data?.colour }}</td>
                                                            </tr>
                                                        </tbody>

                                                    </table>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else>
                            <div class="col-md-12 norecord">
                                <div style="font-weight: bold;font-size: 30px;font-family: system-ui;">No record found.
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </section>
        </vue-html2pdf>
        <stock-detail v-if="viewno == 2" :product="productitem"></stock-detail>

    </div>
</template>
<script>

import { mapGetters } from 'vuex'
import axios from 'axios'
import StockDetail from './StockDetail.vue'
import VueHtml2pdf from 'vue-html2pdf'

import Constants from '../../components/utilities/Constants.vue'
export default {
    mixins: [Constants],
    components: {
        StockDetail, VueHtml2pdf
    },
    data() {
        return {
            viewno: 1,
            productitem: [],
            stocklist: [],
            comapnies: [],
            brandid: 0,
            category_id: 0,
            searchtext: '',
            totalpurchasevalue: 0,
            totalqty: 0,
            hideRow: false,
            productid: 0,
            barcodes: [],
            isprint: 0,
            pricelist: 0,
            availability: 0,
            checkstock: [],
            serialno:''
        }
    },
    computed: {
        ...mapGetters(['selectedstoreid', 'attributes', 'categories']),

        // selectedstoreid: function(){

        // }
    },
    mounted() {
        this.initialize();
        this.$store.dispatch("fetchcategories");
        this.$store.dispatch("fetchattributes")

    },
    watch: {
        // selectedstoreid:function(){
        // 	let param = {selectedstore:this.selectedstoreid, fy:this.getCurrentFinancialYear()};
        //     axios.post('api/stock/forstore2',param)
        //         .then(response=>this.processResponseStore(response.data))
        //         .catch(error=>console.log(error));

        // }
    },
    methods: {
        initialize() {
            this.$store.commit('assignloadingstatus', 1)
            setTimeout(() => {
                this.refresh()
            }, 700);
        },
        refresh() {
            this.category_id = 0,
                this.searchtext = '',
                this.brandid = 0
            if (this.selectedstoreid > 0) {
                this.$store.commit('assignloadingstatus', 1)
                let param = { selectedstore: this.selectedstoreid, 
                    fy: this.getCurrentFinancialYear(),
                 }
                this.finalApi(param)
            } else {
                console.log("store id not found")
            }

        },
        search() {
            if (this.selectedstoreid > 0) {
                this.$store.commit('assignloadingstatus', 1)
                let param = {
                    selectedstore: this.selectedstoreid, brandid: this.brandid,
                    fy: this.getCurrentFinancialYear(), 
                    searchtext: this.searchtext, categoryid: this.category_id,barcode:this.serialno
                }
                this.finalApi(param)
            } else {
                alert("Store not found")
            }
        },
        finalApi(param) {
            axios.post('api/stock/forstore2', param)
                .then((response) => this.processResponse(response.data))
                .catch((error) => console.log(error))
        },
        processResponse(data) {
            if (data != null) {
                let stock = data.sort(function (a, b) {
                    return a.finalquantity - b.finalquantity
                }
                )
                this.stocklist = stock
                this.checkstock = stock
                let brand = this.attributes.find((block) => block.id == 2);
                this.comapnies = brand?.predefinedvalues
                this.getAllTotal(data)
            }
            this.$store.commit('assignloadingstatus', 0)
        },
        getAllTotal(data) {
            let temp = 0
            let value = 0
            data.forEach((element) => {
                temp = temp + parseFloat(element.finalquantity)
                if (element.finalquantity != 0) {
                    value = value + (parseFloat(element.product.cp) * parseFloat(element.finalquantity))
                }

            });
            this.totalqty = parseFloat(temp).toFixed(2)
            this.totalpurchasevalue = parseFloat(value).toFixed(2)
        },
        searchFun() {
            let param = { category_id: this.category_id, brandid: this.brandid, }
            console.log("param", param)
        },
        // processResponseStore(data){
        // 	//this.currentstock=data
        // 	this.$store.commit('assignloadingstatus',0)
        //     console.log("data new",data)
        //     this.$store.commit('assignstocklist',data)
        //     console.log("New purchase ",this.purchasestock)
        // 	// this.$store.commit('assigncurrentstock',data)
        // },

        detail(item) {
            this.viewno = 2
            this.productitem = item.product
            console.log("productitem", this.productitem)
            let param = { selectedstore: this.selectedstoreid, productid: this.productitem.id, 
                fy: this.getCurrentFinancialYear() 
            };
            console.log("param", param)
            axios.post('api/stock/details2', param)
                .then(response => this.processDetailResponse(this.productitem.id, response.data))
                .catch(error => this.showError(error));

        },
        processDetailResponse(productid, data) {
            console.log(productid)
            console.log(data)
            this.$store.commit('assigntransactiondetails', data.stocks);
            // this.$store.commit('assignselectedproductid', productid);
            // this.$store.commit('assignselectedproduct', data.product);
            // // this.$store.commit('assigndailystock', data.daily);
            // this.$store.commit('assigndailycalculationstock', data.stockbalance);
            // this.$store.commit('assigndetailsview', true);
            // this.$store.commit('assignloadingstatus', 0)
        },
        // getRetail(item){
        //     let retail =  parseFloat(item.product?.sp)*(1+(item?.product?.retail/100))
        //     return retail.toFixed(2) ??0
        // },
        // getWholeSale(item){
        //     let wholesale = parseFloat(item.product.sp)*(1+(item.product.wholesale/100))
        //     return wholesale.toFixed(2) ??0
        // },
        // getEmiData(item) {
        //     let value = JSON.parse(item?.product?.emi_cost)
        //     let emi = value?.emi_cost ?? 0
        //     return emi
        // },
        getcompany(attribute_value_ids) {
            let brand = this.attributes.find((block) => block.id == 2);
            if (typeof brand !== "undefined") {
                let companyname = brand.predefinedvalues.find(block => block.id == attribute_value_ids);
                if (typeof companyname != "undefined") {
                    return companyname.value;
                }
            }
            return attribute_value_ids;
        },
        fetchBarcode(item) {
            if (!this.hideRow) {
                let param = { productid: item.productid, storeid: this.selectedstoreid }
                axios.post('api/available/barcode/fetch', param)
                    .then((response) => this.processBarcodeResponse(response.data))
                    .catch((error) => console.log(error))
            } else {
                this.hideRow = false
            }

        },
        processBarcodeResponse(data) {
            if (data.barcode.length > 0) {
                this.productid = data?.barcode[0]?.productid
                this.barcodes = data.barcode
                this.hideRow = !this.hideRow
            } else {
                this.$notify({ text: 'No data found', type: 'warn' })
            }
        },
        check(item) {
            if (item?.productid == this.productid) {
                return true
            }
        },
        onProgress(progress) {
            this.progress = progress;
            console.log(`PDF generation progress: ${progress}%`)
        },
        async beforeDownload({ html2pdf, options, pdfContent }) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i)
                    pdf.setFontSize(11)
                    pdf.setTextColor(150)
                    pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                }
            }).save()
        },
        hasDownloaded() {
            this.$parent.generate = false
            this.isprint = 0
        },
        generateReport() {
            this.isprint = 1
            this.$refs.html2Pdf.generatePdf()
        },
        checkAvailability() {
            if (this.availability == 1 && this.availability == '1') {
                this.stocklist = this.checkstock.filter(o => o.finalquantity > 0)
            } else if (this.availability == 2 && this.availability == '2') {
                this.stocklist = this.checkstock.filter(o => o.finalquantity < 1)
            } else {
                this.stocklist = this.checkstock
            }
        },
        // generatepdf() {
        // 	// this.spinner = true
        // 	axios({
        // 		url: 'https://cn373vzw7i.execute-api.ap-south-1.amazonaws.com/api/transactiondetails/stock?selectedstore=' + this.selectedstoreid + '&type=' + 2, // File URL Goes Here
        // 		method: 'GET',
        // 		responseType: 'blob',
        // 	}).then((res) => {
        // 		window.open(URL.createObjectURL(res.data));
        // 	})
        // 		.then(response => {
        // 			// this.spinner = false
        // 			var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        // 			var fileLink = document.createElement('a');
        // 			fileLink.href = fileURL;
        // 			fileLink.setAttribute('download', 'file.pdf');
        // 			document.body.appendChild(fileLink);
        // 			fileLink.click();
        // 		})
        // 		.catch(error => console.log(error));
        // },

    }
}
</script>
<style scoped>
tbody tr {
    background: #fff;
}
</style>